import React from 'react'

/* Mobile grid => 16 columns | no tailwind prefix up to 1024px -- generally we ignore md: prefix
 * 1 6 2 6 1 — no gutters or pillars, have to convert everything to serapate columns
 * By default every columns extends horizontally. To split columns `shrinkOnMobile` prop must be used.
 *
 * Full desktop grid => 36 columns | lg: tailwind prefix from 1024px
 * 1 4 4 8 2 8 4 4 1
 *
 * Parts in play:
 * - left pillar
 * - left column
 * - right column
 * - right pillar
 *
 * Combinations:
 * - full width
 * - left pillar with gutter
 * - left column with gutter
 * - right pillar with gutter
 * - right column with gutter
 * - gutter to gutter
 */

const DesignRow = (props) => {
	const gridClassName = `${props.className || ''}
				grid grid-cols-16 gap-y-7 auto-rows-auto
				lg:grid-cols-36`.replace(/\s\s+/g, ' ')

	const mobileHalfColumn = ['col-start-2 col-span-6', 'col-start-10 col-span-6']
	const mobileStyles = {
		// leftEdge: 'hidden',
		// rightEdge: 'hidden',
		leftPillar: 'col-start-2 col-span-4',
		leftColumn: 'col-start-2 col-span-6',
		rightColumn: 'col-start-10 col-span-6',
		rightPillar: 'col-start-12 col-span-4',
		fullWidth: 'col-start-2 col-span-14',
	}

	// secondary combinations get simplified on mobile
	const secondaryCombinations = {
		gutterToGutter: 'fullWidth',
		columnToColumn: 'fullWidth',
		leftPillarToColumn: 'leftColumn',
		leftColumnWithGutter: 'leftColumn',
		rightColumnWithGutter: 'rightColumn',
		leftPillarWithGutter: 'leftColumn',
		leftImage: 'leftColumn',
		rightImage: 'rightColumn',
		imageWidth: 'fullWidth',
	}
	Object.keys(secondaryCombinations).forEach(
		(partKey) =>
			(mobileStyles[partKey] = mobileStyles[secondaryCombinations[partKey]])
	)

	const largerStyles = {
		leftEdge: `col-start-1 col-span-1`,
		leftPillar: `lg:col-start-2 lg:col-span-4`,
		leftColumn: `lg:col-start-10 lg:col-span-8`,
		leftColumnWithGutter: `lg:col-start-6 lg:col-span-12`,
		leftPillarToColumn: `lg:col-start-2 lg:col-span-8`,
		leftPillarWithGutter: `lg:col-start-2 lg:col-span-8`,
		leftImage: `lg:col-start-2 lg:col-span-16`,
		rightColumn: `lg:col-start-20 lg:col-span-8`,
		rightPillar: `lg:col-start-32 lg:col-span-4`,
		columnToColumn: `lg:col-start-10 lg:col-span-18`,
		gutterToGutter: `lg:col-start-6 lg:col-span-26`,
		rightColumnWithGutter: `lg:col-start-20 lg:col-span-12`,
		rightImage: `lg:col-start-20 lg:col-span-16`,
		imageWidth: `lg:col-start-4 lg:col-span-30`,
		fullWidth: `lg:col-start-2 lg:col-span-34`,
		rightEdge: `lg:col-start-36 lg:col-span-1`,
	}
	const partsPriority = [
		'leftEdge',
		'leftPillar',
		'leftPillarWithGutter',
		'leftPillarToColumn',
		'leftColumn',
		'leftColumnWithGutter',
		'leftImage',
		'rightColumn',
		'rightColumnWithGutter',
		'rightPillar',
		'rightImage',
		'columnToColumn',
		'gutterToGutter',
		'imageWidth',
		'fullWidth',
		'rightEdge',
	]
	const partKeysToRender = partsPriority.filter((partKey) =>
		props.hasOwnProperty(partKey)
	)
	const partsToRender = partKeysToRender.map((partKey, index) => {
		let computedMobile = ''

		if (!props.shrinkOnMobile) {
			computedMobile = mobileStyles.fullWidth
		} else {
			if (partKeysToRender.length < 3) {
				computedMobile = mobileStyles[partKey]
			} else {
				computedMobile = mobileHalfColumn[index % 2]
			}
		}

		return {
			className: [computedMobile, largerStyles[partKey]]
				.join(' ')
				.replace(/\s\s+/g, ' '),
			content: props[partKey],
		}
	})

	const rowProps = { className: gridClassName }
	if (props?.style) {
		rowProps.style = props.style
	}

	return (
		<div {...rowProps}>
			{partsToRender.map((part, index) => (
				<div className={part.className} key={`part-${index}`}>
					{part.content}
				</div>
			))}
		</div>
	)
}

export default DesignRow
