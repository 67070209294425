import React from 'react'
import {
	Heading1,
	Heading2,
	Heading3,
	Heading4,
	Heading5,
	RegularText,
	IntenseText,
	CtaLink,
} from 'components/design'

const StyleGuide = () => (
	<main className="bg-white p-8">
		<Heading1>H1: What is up, Detroit? LH 1 | DT 56px | MB 40px </Heading1>
		<div className="h-12"></div>

		<Heading2>H2: What is up, Detroit? LH 1.2 | DT 40px | MB 30px</Heading2>
		<div className="h-12"></div>

		<Heading3>H3: What is up, Detroit? LH 1.2 | DT 24px | MB 20px</Heading3>
		<div className="h-12"></div>

		<Heading4>H4: What is up, Detroit? LH 1.6 | DT 20px | MB 16px</Heading4>
		<div className="h-12"></div>

		<Heading5>
			H5: What is up, Detroit? LH 1.5 | DT 16px | MB 12px | Letter spacing 1px
		</Heading5>
		<div className="h-12"></div>

		<RegularText>
			<p>Regular paragraph goes like this:</p>
			<p>
				Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy
				nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
				wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit
				lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum
				iriure dolor in hendrerit in vulputate velit esse molestie
			</p>
		</RegularText>
		<div className="h-12"></div>

		<IntenseText>
			<p>Intense paragraph, in turn:</p>
			<p>The link is broken or it has been removed. Try these options instead:</p>
		</IntenseText>

		<CtaLink className="mt-12" to="/contacts/" label="Reach out" />
		<CtaLink className="mt-12 !w-48" to="/contacts/" label="Reach out" />
	</main>
)

export default StyleGuide
