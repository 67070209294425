import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import DesignRow from './DesignRow'

const Heading1 = (props) => <h1 className={props.className || ''}>{props.children}</h1>
const Heading2 = (props) => <h2 className={props.className || ''}>{props.children}</h2>
const Heading3 = (props) => <h3 className={props.className || ''}>{props.children}</h3>
const Heading4 = (props) => <h4 className={props.className || ''}>{props.children}</h4>
const Heading5 = (props) => <h5 className={props.className || ''}>{props.children}</h5>
const RegularText = (props) => (
	<div className={`regular-text ${props.className || ''}`}>{props.children}</div>
)
const IntenseText = (props) => (
	<div className={`intense-text ${props.className || ''}`}>{props.children}</div>
)
const ExpressiveText = (props) => (
	<div className={`expressive-text ${props.className || ''}`}>{props.children}</div>
)
const EmailLink = ({ email }) => (
	<a className="accent-hover" href={`mailto:${email}`}>
		{email}
	</a>
)

const formatPhoneHref = (number) => `tel:${number.replace(/\s/g, '')}`

const PhoneLink = ({ number: defaultNumber, countrySpecific }) => {
	const [href, setHref] = useState(formatPhoneHref(defaultNumber))
	const [displayed, setDisplayed] = useState(defaultNumber)

	useEffect(() => {
		if (typeof window !== 'object' || typeof window.document !== 'object') return

		let countryCode = ''
		const cookies = document.cookie.split(';')
		const countryCodeCookie = cookies.find((cookie) =>
			cookie.trim().startsWith('countryCode=')
		)

		if (countryCodeCookie) {
			countryCode = countryCodeCookie.split('=')[1]
		}

		if (!countryCode || countryCode.length !== 2) return

		const matchingPhone = countrySpecific.find(
			(phone) => phone.country_code === countryCode
		)
		if (!matchingPhone) return

		const number = matchingPhone.geo_phone_formatted
		setHref(formatPhoneHref(number))
		setDisplayed(number)
	}, [countrySpecific])

	return (
		<a className="accent-hover" href={href}>
			{displayed}
		</a>
	)
}
// const CtaBackbone = ({ component, propsToPass, className, label }) => {
// 	const props = {
// 		...propsToPass,
// 		className:
// 			`group relative flex h-12 w-40 items-center overflow-hidden bg-black lg:h-16 lg:w-80 ` +
// 			className +
// 			` after:absolute after:z-0 after:block after:h-full after:w-full after:translate-x-full after:bg-accent after:transition-transform after:duration-300 after:ease-in-out hover:after:translate-x-0`,
// 		children: [
// 			<i className="z-[1] mr-screen-step-2/5 ml-screen-step-1/2 inline-block h-2.5 w-2.5 bg-accent transition-colors duration-300 group-hover:bg-white lg:h-3 lg:w-3" />,
// 			<span className="z-[1] text-xs font-semibold uppercase leading-none tracking-px text-white lg:text-sm">
// 				{label}
// 			</span>,
// 		],
// 	}
// 	return component(props)
// }
// (
// 	<CtaBackbone component={Link} propsToPass={{ to }} className={className} />
// )

const parentClass = `group relative flex h-12 w-40 items-center overflow-hidden bg-black lg:h-16 lg:w-80`
const afterClass = `before:absolute before:block before:h-full before:w-full before:translate-x-full before:bg-accent before:transition-transform before:duration-300 before:ease-in-out hover:before:translate-x-0`
const iClass = `mr-screen-step-1/2 absolute left-screen-step-3/4 inline-block h-2.5 w-2.5 bg-accent transition-colors duration-300 group-hover:bg-white lg:left-screen-step-1/2 lg:mr-screen-step-2/5 lg:h-3 lg:w-3`
const spanClass = `text-xs absolute left-screen-step-2 uppercase leading-none tracking-wider text-white tracking-px lg:text-sm font-semibold whitespace-nowrap transition-colors duration-300  lg:left-screen-step-5/4`

const CtaLink = (props) => {
	const { className, label, variation, ...propsToPass } = props
	return (
		<Link {...propsToPass} className={[parentClass, className, afterClass].join(' ')}>
			<i className={iClass} />
			<span className={spanClass}>{label}</span>
		</Link>
	)
}
const CtaButton = (props) => {
	const { className, label, variation, ...propsToPass } = props

	let appliedParentClass = parentClass
	let appliedSpanClass = spanClass
	if (variation === 'white') {
		appliedParentClass = appliedParentClass.replace('bg-black', 'bg-white')
		appliedSpanClass =
			appliedSpanClass.replace('text-white', 'text-black') +
			' group-hover:text-white'
	}

	return (
		<button
			{...propsToPass}
			className={[
				appliedParentClass,
				`disabled:cursor-not-allowed disabled:bg-opacity-50 lg:h-16 lg:w-80`,
				className,
				afterClass,
			].join(' ')}
		>
			<i className={iClass} />
			<span className={appliedSpanClass}>{label}</span>
		</button>
	)
}

export {
	DesignRow,
	Heading1,
	Heading2,
	Heading3,
	Heading4,
	Heading5,
	IntenseText,
	RegularText,
	EmailLink,
	PhoneLink,
	CtaButton,
	CtaLink,
	ExpressiveText,
}